/* You can add global styles to this file, and also import other style files */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}

p {
  margin: 0;
}

button {
  background-color: #DD8000;
  color: black;
  border: 2px solid;
  border-radius: 5px;
  padding: 5px;
  font-size: 20px;
}

button:disabled {
  background-color: #A83;
  color: #666;
}

.button-red {
  background-color: #F11;
  border: 2px solid;
  border-radius: 5px;
}

.space {
  flex-grow: 1;
}

.clickable-span {
  padding: 5px;
}

.clickable-span:hover {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
}
